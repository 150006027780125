import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { nodeName } from "jquery"
import Img from "gatsby-image"
const Header = props => {
  const blogdata = props.data.allMarkdownRemark.edges
  const data = blogdata.filter(
    dat =>
      dat.node.frontmatter.category === props.filter.category &&
      dat.node.fields.slug !== props.filter.slug
  )

  return (
    <React.Fragment>
      {data ? (
        <div className="col-md-12 text-center">
          <h2 className="h1">Related Blogs</h2>
        </div>
      ) : (
        ""
      )}
      {data.map((blog, index) => {
        const title = blog.node.frontmatter.title || blog.node.fields.slug
        return (
          index < 3 && (
            <div className="col-md-4">
              <br></br>
              <div className="BlogCardMini" key={blog.node.fields.slug}>
                <Link to={"/blog" + blog.node.fields.slug}>
                  <h4 className="BlogTitle" title={title}>
                    {title}
                  </h4>
                  <div>
                    <Img
                      fluid={
                        blog.node.frontmatter.featuredImage.childImageSharp
                          .fluid
                      }
                      style={{ borderRadius: "16px" }}
                      className="img-responsive"
                      title={title}
                      alt={title}
                    />
                  </div>
                  <br></br>
                  <p className="BlogDate">
                    {/* {blog.node.frontmatter.date} |{" "} */}
                    {blog.node.frontmatter.author}
                  </p>
                  <p
                    className="BlogDesc"
                    dangerouslySetInnerHTML={{ __html: blog.node.excerpt }}
                  />
                  <br></br>
                  <Link
                    className="claimnowButton"
                    to={"/blog" + blog.node.fields.slug}
                  >
                    Read More
                  </Link>
                  <br></br>
                </Link>
                <br></br>
              </div>
            </div>
          )
        )
      })}
    </React.Fragment>
  )
}

//return(<h1></h1>)

const ReleatedBlog = props => {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
              fileAbsolutePath: { regex: "/(blog)/" }
              frontmatter: { templateKey: { eq: "blog-post" } }
            }
          ) {
            edges {
              node {
                excerpt
                fields {
                  slug
                }
                frontmatter {
                  date
                  title
                  author
                  category
                  tags
                  keyword
                  featuredImage {
                    relativeDirectory
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
            totalCount
          }
        }
      `}
      render={data => <Header data={data} filter={props.filter} />}
    />
  )
}

export default ReleatedBlog
